<template>
  <div class="setting-item s-input">
    <validation-provider
      :rules="validationRules"
      :name="`«${localSetting.name}»`"
      v-slot="v"
      tag="div"
    >
      <esmp-input
        v-model="localSetting.value"
        :label="localSetting.name"
        :error-message="v.errors[0] || ''"
      />
    </validation-provider>
  </div>
</template>

<script>

export default {
  name: 'SInput',
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
    mask: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    validationRules() {
      if (this.mask) {
        return { regex: this.mask };
      }
      return {};
    },
  },
};
</script>
